// https://getbootstrap.com/docs/5.0/forms/validation/
// Example starter JavaScript for disabling form submissions if there are invalid fields
(function () {
    const form = document.querySelector('.needs-validation');

    form.addEventListener('submit', function (event) {
        event.preventDefault();
        event.stopPropagation();

        if (!form.checkValidity()) {
            form.classList.add('was-validated');
            return;
        }

        const registrationFormData = {
            FullName: document.getElementById('registrationFormFullName').value,
            CompanyName: document.getElementById('registrationFormCompanyName').value,
            PhoneNumber: document.getElementById('registrationFormPhoneNumber').value,
            EmailAddress: document.getElementById('registrationFormEmail').value,
            LanguageId: document.getElementById('hungarian-language-selector').classList.contains('active') ? 'hu' : 'en'
        };

        postRegistrationFormData(registrationFormData)
            .then(response => {
                console.log(response);
                alert('Üzenetét sikeresen elküldtük!');
            })
            .catch(error => {
                console.error(error);
                alert('Sikertelen az üzenet elküldése!')
            })
            .finally(() => event.target.reset());

    }, false)

})();


async function postRegistrationFormData(data) {
    const response = await fetch(process.env.POST_REGISTRATION_FORM_URL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data),
    });
    return response;
};



