export const textResources = {
    english: {
        languageSelectorEnglish: 'English',
        languageSelectorHungarian: 'Hungarian',
        languageSelectorText: 'en',
        buttonTitle: 'en-Regisztrálok',
        aboutHeader: 'en-Hozamtermelő, bérbeadott ingatlanok platformja',
        aboutHeaderSecondary: 'en-Platformunkat a biztonságra építettük.',
        aboutElementOneHeader: 'en-Átvilágított ingatlanok',
        aboutElementOneText: 'en-Nem vesztegetjük idejét. Kizárólag megfelelő szakértelemmel előszűrt vételi lehetőségeket listázunk.',
        aboutElementTwoHeader: 'en-Garantált anonimitás',
        aboutElementTwoText: 'en-Ügyfeleink és ingatlanjaink anonimitását portálunk és folyamataink teljes egészében biztosítja alkalmazott technológiáinknak köszönhetően.',
        aboutElementThreeHeader: 'en-Exkluzív ingatlanok',
        aboutElementThreeText: 'en-Büszkék vagyunk kínálatunkra, mely máshol nem található vételi lehetőségeket is kínál Önnek.',
        aboutElementFourHeader: 'en-Hozamgarancia minősítés',
        aboutElementFourText: 'en-Üzleti oldalról átlátható, hozamtermelő és bérbeadott ingatlanokat jelenítünk meg.',
        aboutElementFiveHeader: 'en-Adatszoba',
        aboutElementFiveText: 'en-Minden ingatlanunkhoz adatszobát nyitunk meg, eladói jóváhagyás után. Átláthatóságot biztosítunk.',
        aboutElementSixHeader: 'en-Online ügyintézés',
        aboutElementSixText: 'en-Hónapokat vagyunk képesek megtakarítani az Ön számára. Vételi folyamataink igény szerint, akár teljesen online kivitelezhetőek.',
        aboutMarketingQuestion: 'en-Hozamtermelő ingatlant vásárolnál?',
        companyBannerHeader: 'en-Minimum 5% éves hozamot garantáló hozamtermelő ingatlant keresel?',
        companyBannerHeaderSecondaryPartOne: 'en-Budapest területén <strong>1,3 millió euróig</strong>',
        companyBannerHeaderSecondaryPartTwo: 'en-lehetőséget találtunk.',
        headerMenuOne: 'en-Miért a Leopard Investment?',
        headerMenuTwo: 'en-A folyamat',
        headerMenuThree: 'en-Sikertörténetek',
        headerMenuFour: 'en-Kérdések',
        processHeader: 'en-Folyamatunk ajánlattól a sikeres vételig',
        processOneHeader: 'en-Ingatlan<br> előszűrés',
        processOneText: 'en-Az Ön számára a legmegfelelőbb ingatlanokat biztosítjuk adatbázisunkból, az előre megadott paraméterek alapján.',
        processTwoHeader: 'en-Ingatlan<br> minősítés',
        processTwoText: 'en-Kiválasztja, mely ingatlan adatszobájához szeretne hozzáférést.',
        processThreeHeader: 'en-Adatszoba<br> megnyitása',
        processThreeText: 'en-Megnyitjuk az adatszobát az átvilágításhoz, mely során az ingatlanhoz tartozó dokumentumokba betekintést biztosítunk (alaprajz, bérleti szerződések, CAPEX terv)',
        processFourHeader: 'en-Ajánlattétel',
        processFourText: 'en-Lehetőség nyílik az ingatlanra vételi ajánlatot tenni egyedi ajánlat vagy aukció útján.',
        successStoriesHeader: 'en-Ügyfeleink sikertörténetei',
        successStoryOneQuote: 'en-Budapest belvárosi üzlethelyiséget kerestem. Segítségükkel 8% nettó hozamú ingatlant sikerült megvásárolnunk, 5 hét alatt, 2022-ben.',
        successStoryOneTitle: 'en-Befektető',
        successStoryTwoQuote: 'en-Jó lokációjú, hozamtermelő irodákat segítettek találni Budapest belvárosában, a VI. kerületben 2022. szeptemberében, nagyon kedvező, br. 604 000 HUF m2 áron, melyet meg is vettünk.',
        successStoryTwoTitle: 'en-LDSZ Kft. ügyvezető',
        successStoryThreeQuote: 'en-Budapesti, 7. kerületi hostelünk eladását köszönhetjük nekik 2022-ben, a tranzakciót 3 hónap alatt bonyolították le.',
        successStoryThreeTitle: 'en-Befektető',
        frequentlyAskedQuestionsHeader: 'en-Gyakran ismételt kérdések',
        frequentlyAskedQuestionOne: 'en-Mit tekintünk hozamtermelő ingatlannak?',
        frequentlyAskedQuestionOneAnswer: 'en-Kizárólag ellenőrzött üzleti háttérrel rendelkező ingatlanokat listázunk. Az ingatlanok mellé aktív bérleti szerződéseket, alaprajzot, energetikai tanusítványokat mellékelünk.',
        frequentlyAskedQuestionTwo: 'en-Hogyan tudok kapcsolatba lépni a hirdetővel?',
        frequentlyAskedQuestionTwoAnswer: 'en-Platformunkon keresztül minden hirdetőnkhöz lehetőséget nyújtunk kapcsolatfelvételre. Itt nyílik belépési lehetőség az Adatszobába is, ahol az ingatlanhoz tartozó különböző privát dokumentum kerül megosztásra.',
        frequentlyAskedQuestionThree: 'en-Mitől lesz hiteles a hirdetett hozamtermelés?',
        frequentlyAskedQuestionThreeAnswer: 'en-Megbízott szakértőink sok éves tapasztalattal rendelkező kollegáink, akik névvel, aláírásukkal vállalják hiteles szakvéleményüket. Ezen kívül a hozamszámítás képlete minden ingatlanunknál publikus.',
        frequentlyAskedQuestionFour: 'en-Milyen vételi opciók állnak rendelkezésre?',
        frequentlyAskedQuestionFourAnswer: 'en-Ingatlanjainkra egyedi ajánlat vagy aukció formájában lehet ajánlatot tennie a potenciális vevőknek.',
        frequentlyAskedQuestionFive: 'en-Miből gondoljuk, hogy portálunk hiánypótló?',
        frequentlyAskedQuestionFiveAnswer: 'en-Jelenleg Magyarország területén nincs még egy online platform, ahol kizárólag exkluzív hozamtermelő ingatlanok kerülnek listázásra. Láthatóvá tesszük a lehetőségeket.',
        feedbackHeader: 'en-Jó lokációjú, hozamtermelő irodákat segítettek találni Budapest belvárosában, a VI. kerületben 2022. szeptemberében, nagyon kedvező, br. 604 000 HUF m2 áron, melyet meg is vettünk.',
        feedbackJobTitle: 'en-LDSZ Kft. Ügyvezető',
        footerAddress: 'en-1024 Budapest, Kisrókus utca 23.',
        footerPrivacyPolicy: 'en-Adatkezelési szabályzat',
        footerGeneralTermsAndConditions: 'en-Általános szerződési feltételek',
        footerPoweredBy: 'Powered by',
        footerAllRightsReserved: 'all rights reserved',
        registrationHeader: 'en-Regisztráció',
        registrationFullName: 'en-Teljes név',
        registrationFullNameFeedback: 'Please complete this required field.',
        registrationCompanyName: 'en-Cég név',
        registrationCompanyNameFeedback: 'Please provide a company name.',
        registrationPhoneNumber: 'en-Mobiltelefonszám',
        registrationPhoneNumberFeedback: 'Please provide a phone number.',
        registrationEmail: 'en-E-mail cím',
        registrationEmailFeedback: 'Please provide a valid email.',
        registrationAgreeToPartOne: 'I read and agree to the&nbsp',
        registrationAgreeToPartTwo: '!',
        registrationTermsAndConditions: 'terms and conditions',
        registrationFormPrivacyPolicy: 'privacy policy',
        registrationTermsAndConditionsFeedback: 'You must agree before submitting.',
        registrationSubmitButton: 'Submit',
    },
    hungarian: {
        languageSelectorEnglish: 'angol',
        languageSelectorHungarian: 'magyar',
        languageSelectorText: 'hu',
        buttonTitle: 'Regisztrálok',
        aboutHeader: 'Hozamtermelő, bérbeadott ingatlanok platformja',
        aboutHeaderSecondary: 'Platformunkat a biztonságra építettük.',
        aboutElementOneHeader: 'Átvilágított ingatlanok',
        aboutElementOneText: ' Nem vesztegetjük idejét. Kizárólag megfelelő szakértelemmel előszűrt vételi lehetőségeket listázunk.',
        aboutElementTwoHeader: 'Garantált anonimitás',
        aboutElementTwoText: 'Ügyfeleink és ingatlanjaink anonimitását portálunk és folyamataink teljes egészében biztosítja alkalmazott technológiáinknak köszönhetően.',
        aboutElementThreeHeader: 'Exkluzív ingatlanok',
        aboutElementThreeText: 'Büszkék vagyunk kínálatunkra, mely máshol nem található vételi lehetőségeket is kínál Önnek.',
        aboutElementFourHeader: 'Hozamgarancia minősítés',
        aboutElementFourText: 'Üzleti oldalról átlátható, hozamtermelő és bérbeadott ingatlanokat jelenítünk meg.',
        aboutElementFiveHeader: 'Adatszoba',
        aboutElementFiveText: 'Minden ingatlanunkhoz adatszobát nyitunk meg, eladói jóváhagyás után. Átláthatóságot biztosítunk.',
        aboutElementSixHeader: 'Online ügyintézés',
        aboutElementSixText: 'Hónapokat vagyunk képesek megtakarítani az Ön számára. Átvilágítási folyamataink teljesen online történnek.',
        aboutMarketingQuestion: 'Hozamtermelő ingatlant vásárolnál?',
        companyBannerHeader: 'Minimum 5% éves hozamot garantáló hozamtermelő ingatlant keresel?',
        companyBannerHeaderSecondaryPartOne: 'Budapest területén <strong>1,3 millió euróig</strong>',
        companyBannerHeaderSecondaryPartTwo: 'lehetőséget találtunk.',
        headerMenuOne: 'Miért a Leopard Investment?',
        headerMenuTwo: 'A folyamat',
        headerMenuThree: 'Sikertörténetek',
        headerMenuFour: 'Kérdések',
        processHeader: 'Folyamatunk ajánlattól a sikeres vételig',
        processOneHeader: 'Ingatlan<br> előszűrés',
        processOneText: 'Az Ön számára a legmegfelelőbb ingatlanokat biztosítjuk adatbázisunkból, az előre megadott paraméterek alapján.',
        processTwoHeader: 'Ingatlan<br> minősítés',
        processTwoText: 'Kiválasztja, mely ingatlan adatszobájához szeretne hozzáférést.',
        processThreeHeader: 'Adatszoba<br> megnyitása',
        processThreeText: 'Megnyitjuk az adatszobát az átvilágításhoz, mely során az ingatlanhoz tartozó dokumentumokba betekintést biztosítunk (alaprajz, bérleti szerződések, CAPEX terv)',
        processFourHeader: 'Ajánlattétel',
        processFourText: 'Lehetőség nyílik az ingatlanra vételi ajánlatot tenni egyedi ajánlat vagy aukció útján.',
        successStoriesHeader: 'Ügyfeleink sikertörténetei',
        successStoryOneQuote: 'Budapest belvárosi üzlethelyiséget kerestem. Segítségükkel 8% nettó hozamú ingatlant sikerült megvásárolnunk, 5 hét alatt, 2022-ben.',
        successStoryOneTitle: 'Befektető',
        successStoryTwoQuote: 'Jó lokációjú, hozamtermelő irodákat segítettek találni Budapest belvárosában, a VI. kerületben 2022. szeptemberében, nagyon kedvező, br. 604 000 HUF m2 áron, melyet meg is vettünk.',
        successStoryTwoTitle: 'LDSZ Kft. ügyvezető',
        successStoryThreeQuote: 'Budapesti, 7. kerületi hostelünk eladását köszönhetjük nekik 2022-ben, a tranzakciót 3 hónap alatt bonyolították le.',
        successStoryThreeTitle: 'Befektető',
        frequentlyAskedQuestionsHeader: 'Gyakran ismételt kérdések',
        frequentlyAskedQuestionOne: 'Mit tekintünk hozamtermelő ingatlannak?',
        frequentlyAskedQuestionOneAnswer: 'Kizárólag ellenőrzött üzleti háttérrel rendelkező ingatlanokat listázunk. Az ingatlanok mellé aktív bérleti szerződéseket, alaprajzot, energetikai tanusítványokat mellékelünk.',
        frequentlyAskedQuestionTwo: 'Hogyan tudok kapcsolatba lépni a hirdetővel?',
        frequentlyAskedQuestionTwoAnswer: 'Platformunkon keresztül minden hirdetőnkhöz lehetőséget nyújtunk kapcsolatfelvételre. Itt nyílik belépési lehetőség az Adatszobába is, ahol az ingatlanhoz tartozó különböző privát dokumentum kerül megosztásra.',
        frequentlyAskedQuestionThree: 'Mitől lesz hiteles a hirdetett hozamtermelés?',
        frequentlyAskedQuestionThreeAnswer: 'Megbízott szakértőink sok éves tapasztalattal rendelkező kollegáink, akik névvel, aláírásukkal vállalják hiteles szakvéleményüket. Ezen kívül a hozamszámítás képlete minden ingatlanunknál publikus.',
        frequentlyAskedQuestionFour: 'Milyen vételi opciók állnak rendelkezésre?',
        frequentlyAskedQuestionFourAnswer: 'Ingatljainkat árverésre bocsájtjuk online platformunkon, ahol megadott licitlépcsők szerint várjuk Ügyfeleink ajánlatát.',
        frequentlyAskedQuestionFive: 'Miből gondoljuk, hogy portálunk hiánypótló?',
        frequentlyAskedQuestionFiveAnswer: 'Jelenleg Magyarország területén nincs még egy online platform, ahol kizárólag exkluzív hozamtermelő ingatlanok kerülnek listázásra. Láthatóvá tesszük a lehetőségeket.',
        feedbackHeader: 'Jó lokációjú, hozamtermelő irodákat segítettek találni Budapest belvárosában, a VI. kerületben 2022. szeptemberében, nagyon kedvező, br. 604 000 HUF m2 áron, melyet meg is vettünk.',
        feedbackJobTitle: 'LDSZ Kft. Ügyvezető',
        footerAddress: '1024 Budapest, Kisrókus utca 23.',
        footerPrivacyPolicy: 'Adatkezelési szabályzat',
        footerGeneralTermsAndConditions: 'Általános szerződési feltételek',
        footerPoweredBy: 'Fejlesztette',
        footerAllRightsReserved: 'minden jog fenntartva',
        registrationHeader: 'Regisztráció',
        registrationFullName: 'Teljes név',
        registrationFullNameFeedback: 'Kérjük, töltsd ki ezt a kötelező mezőt.',
        registrationCompanyName: 'Cég név',
        registrationCompanyNameFeedback: 'Kérjük, add meg a cég nevét.',
        registrationPhoneNumber: 'Mobiltelefonszám',
        registrationPhoneNumberFeedback: 'Kérjük, adj meg egy telefonszámot.',
        registrationEmail: 'E-mail cím',
        registrationEmailFeedback: 'Kérjük, adj meg egy érvényes e-mailt.',
        registrationTermsAndConditions: 'általános szerződési feltételeket',
        registrationFormPrivacyPolicy: 'adatvédelmi szabályzatot',
        registrationAgreeToPartOne: 'Az&nbsp;',
        registrationAgreeToPartTwo: 'elolvastam és elfogadom!',
        registrationTermsAndConditionsFeedback: 'Beküldés előtt el kell fogadnia.',
        registrationSubmitButton: 'Elküldés',
    }
};