import { textResources } from './text-resources';

const languageEnglish = 'english';
const languageHungarian = 'hungarian';
// There are no text translations for the page yet, so we only use Hungarian
// const defaultLanguage = navigator.language === 'hu' ? languageHungarian : languageEnglish;
const defaultLanguage = languageHungarian;
changeLanguage(defaultLanguage);

document.querySelector('#english-language-selector')?.addEventListener('click', function () { changeLanguage(languageEnglish) });
document.querySelector('#hungarian-language-selector')?.addEventListener('click', function () { changeLanguage(languageHungarian) });

function changeLanguage(language) {
    document.querySelectorAll('[data-language-resource-key]').forEach(node => {
        const resourceKey = node.dataset.languageResourceKey;

        if (!textResources[language][resourceKey]) {
            console.error('Missing text resource for key: ', resourceKey, ' in the following language: ', language, '!');
            node.innerHTML = 'Text not found!';
            return;
        }

        node.innerHTML = textResources[language][resourceKey];
    });

    Object.keys(textResources).forEach(languageKey => {
        languageKey === language
            ? document.querySelector(`#${languageKey}-language-selector`)?.classList.add('active')
            : document.querySelector(`#${languageKey}-language-selector`)?.classList.remove('active')
    });
}