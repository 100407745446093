const navLinks = document.querySelectorAll('.nav-item');
const menuToggle = document.getElementById('navbarSupportedContent');

navLinks.forEach((link) => {
    if (link.classList.contains('navbar-language-selector-dropdown')) {
        /* We don't want to close the menu in mobile view when the language selector is pressed, 
           since this element itself is a dropdown and we would not be able to choose a language */
        return;
    }

    link.addEventListener('click', () => menuToggle.classList.remove('show'));
});